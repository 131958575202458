// custom change - new file
import { html } from 'lit';

export default (el, nps_info) => {

    const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min);

    const tplNPSItems = (arr) => {
        return html `
            <div class="chat-msg__body-NPS-row"> 
                ${arr.map(item => {
                    return html`
                    <input type="radio" class="chat-msg__body-NPS-row-item" name="rating_${nps_info.id}" id="rate_${nps_info.id}_${item}" value="${item}" />
                    <label class="chat-msg__body-NPS-row-item-label" for="rate_${nps_info.id}_${item}">${item}</label>`
                })}
            </div> `
    }
    const tplNPSButtons = (size) => {
        if(size < 6) {
            return html`${tplNPSItems(range(1, size))}`
        } else {
            return html`
                ${tplNPSItems(range(1, Number((size / 2).toFixed())))}
                ${tplNPSItems(range(Number((size / 2 + 1).toFixed()), size))}`
        }
    }
    return html`
    <div class="chat-msg__body-NPS">
        <h2 class="chat-msg__body-NPS-title">${nps_info.obj.title}</h2>
        <div class="chat-msg__body-NPS-description">
            <h4 class="chat-msg__body-NPS-description-title">${nps_info.obj.minLabel}</h4>
            <h4 class="chat-msg__body-NPS-description-title">${nps_info.obj.maxLabel}</h4>
        </div>
        <div class="chat-msg__body-NPS-rows-container"> 
        ${nps_info.obj.size ? tplNPSButtons(nps_info.obj.size) : null}
        </div>
        <p class="nps-input-feedback nps-input-feedback--not-displayed"></p>
        ${nps_info.button_enabled ? 
            html`<button class="nps-submit-button chat-msg__action-nps-reply" @click=${ev => el.onNPSSubmitClicked(ev, nps_info.id)}>${nps_info.obj.button}</button>
        ` : html`<button class="nps-submit-button chat-msg__action-nps-reply" disabled @click=${ev => el.onNPSSubmitClicked(ev, nps_info.id)}>${nps_info.obj.button}</button>
        `}
    </div>`
}
