// custom change - new file
import { html } from 'lit';

export default (el, my_plan_info) => {
    const has_benefits = Object.keys(my_plan_info.benefits).filter(category => my_plan_info.benefits[category].length > 0);
    const tplDisplayBenefits = (item, category) => {
        return html`<div class="chat-msg__body-myPlan--item-grid">
                        <h4 class="chat-msg__body-myPlan--item-title">${category === "clientBenefitsUnlimited" ? item.name.replace('-1','nelimitat').trimEnd() : item.name.trimEnd()}:</h4>
                        <h4 class="chat-msg__body-myPlan--item-value"><b>${category === "clientBenefitsUnlimited" ? item.total.replace('-1','nelimitat') : item.total}</b></h4>
                    </div>`
    }

    return html`
    <div class="chat-msg__body-myPlan">
        ${ my_plan_info?.base_plan?.price ? html`
            <div class="chat-msg__body-myPlan--header">
                <h4 class="chat-msg__body-myPlan--header-text"><b>${my_plan_info?.base_plan?.name}</b> cu valoarea lunara de:</h4>
                <h4 class="chat-msg__body-myPlan--header-value chat-msg__body-myPlan--item-value"><b>${my_plan_info?.base_plan?.price} ${my_plan_info?.base_plan?.unit}</b></h4>
            </div>
            <div class="chat-msg__body-myPlan--subheader">
                <h4 class="chat-msg__body-myPlan--header-value chat-msg__body-myPlan--item-value">(cu TVA inclus)</h4>
            </div>` : html `
            <div class="chat-msg__body-myPlan--header">
                <h4 class="chat-msg__body-myPlan--header-text"><b>${my_plan_info?.base_plan?.name}</b></h4>
            </div>`

        }
        ${ my_plan_info?.benefits && has_benefits.length > 0 ? html`
            <hr class="myPlan-line-delimiter">
            <h4 class="chat-msg__body-myPlan--header-text chat-msg__body-myPlan--header-category"><b>Beneficii:</b></h4>
            ${ Object.keys(my_plan_info.benefits).map(category => {
                return html`${my_plan_info?.benefits[category].map(item => {
                    return tplDisplayBenefits(item, category)
                })}`
            })}` : null
        }
        ${ my_plan_info?.addons && my_plan_info?.addons.length > 0 ? html`
            <hr class="myPlan-line-delimiter">
            <h4 class="chat-msg__body-myPlan--header-text chat-msg__body-myPlan--header-category"><b>Promotii si optiuni active:</b></h4>
            ${ my_plan_info.addons.map(item => {
                    return html`<div class="chat-msg__body-myPlan--item-grid">
                            <h4 class="chat-msg__body-myPlan--item-title">${item.name.trimEnd()}:</h4>
                            <h4 class="chat-msg__body-myPlan--item-value"><b>${item.price !== "null" ? item.price : 0} ${my_plan_info?.base_plan?.unit}</b></h4>
                        </div>`
                })
            }` : null
        }
    </div>`
}
