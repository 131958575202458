import './message-actions.js';
import './message-body.js';
import 'shared/components/dropdown.js';
import 'shared/modals/message-versions.js';
import 'shared/modals/user-details.js';
import 'shared/registry';
import 'plugins/muc-views/modals/occupant.js';
import tplFileProgress from './templates/file-progress.js';
import log from '@converse/headless/log';
import tplInfoMessage from './templates/info-message.js';
import tplMepMessage from 'plugins/muc-views/templates/mep-message.js';
import tplMessage from './templates/message.js';
import tplMessageText from './templates/message-text.js';
import tplRetraction from './templates/retraction.js';
import tplSpinner from 'templates/spinner.js';
import tplQuickReplies from './templates/quick-replies.js'; // custom change - new line
import tplBillInfoCard from './templates/bill-info-card.js'; // custom change - new line
import tplPaCard from './templates/pa-card.js'; // custom change - new line
import tplPcCard from './templates/pc-card.js'; // custom change - new line
import tplOffersCarousel from './templates/offers-carousel.js'; // custom change - new line
import tplOffersCard from './templates/offers-card.js'; // custom change - new line
import tplThankYouCard from './templates/thank-your-card.js'; // custom change - new line
import tplOOBChargesCard from './templates/oob-charges-summary-card.js' // custom change - new line
import tplOOBChargesDetailedCard from './templates/oob-charges-detailed-card.js' // custom change - new line
import tplOOBExtendedDetailedCard from './templates/oob-extended-detailed-card.js' // custom change - new line
import tplOOBExtendedCarousel from './templates/oob-extended-carousel.js' // custom change - new line
import tplOOBChargesBillMgmtCard from './templates/oob-charges-bill-mgmt.js' // custom change - new line
import tplInfoTooltipModal from './templates/tooltip-modal.js' // custom change - new line
import tplMyPlanCard from './templates/my-plan-card.js' // custom change - new line
import { CustomElement } from 'shared/components/element.js';
import { __ } from 'i18n';
import { _converse, api, converse } from  '@converse/headless/core';
import { getAppSettings } from '@converse/headless/shared/settings/utils.js';
import { getHats } from './utils.js';
import { parseMessageForCommands } from '../../plugins/chatview/utils.js'; // custom change - new line
import './styles/custom-components.scss';

const { Strophe, dayjs } = converse.env;


export default class Message extends CustomElement {

    static get properties () {
        return {
            jid: { type: String },
            mid: { type: String }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    removeElements = (elms) => elms.forEach((el) => el.remove()); // custom change - new function

    async initialize () {
        await this.setModels();
        if (!this.model) {
            // Happen during tests due to a race condition
            log.error('Could not find module for converse-chat-message');
            return;
        }

        const settings = getAppSettings();
        this.listenTo(settings, 'change:render_media', () => {
            // Reset individual show/hide state of media
            this.model.save('hide_url_previews', undefined)
            this.requestUpdate();
        });

        this.listenTo(this.chatbox, 'change:first_unread_id', () => this.requestUpdate());
        this.listenTo(this.model, 'change', () => this.requestUpdate());
        this.model.vcard && this.listenTo(this.model.vcard, 'change', () => this.requestUpdate());

        if (this.model.get('type') === 'groupchat') {
            if (this.model.occupant) {
                this.listenTo(this.model.occupant, 'change', () => this.requestUpdate());
            } else {
                this.listenTo(this.model, 'occupantAdded', () => {
                    this.requestUpdate();
                    this.listenTo(this.model.occupant, 'change', () => this.requestUpdate())
                });
            }
        }
    }

    async setModels () {
        this.chatbox = await api.chatboxes.get(this.jid);
        await this.chatbox.initialized;
        await this.chatbox.messages.fetched;
        this.model = this.chatbox.messages.get(this.mid);
        this.model && this.requestUpdate();
    }

    render () {
        if (!this.model) {
            return '';
        } else if (this.show_spinner) {
            return tplSpinner();
        } else if (this.model.get('file') && this.model.get('upload') !== _converse.SUCCESS) {
            return this.renderFileProgress();
        } else if (['mep'].includes(this.model.get('type'))) {
            return this.renderMEPMessage();
        } else if (['error', 'info'].includes(this.model.get('type'))) {
            return this.renderInfoMessage();
        } else {
            return this.renderChatMessage();
        }
    }

    getProps () {
        return Object.assign(
            this.model.toJSON(),
            this.getDerivedMessageProps()
        );
    }

    renderRetraction () {
        return tplRetraction(this);
    }

    // custom change - added new parameters 'obj' and  'text_message'
    renderMessageText (obj, text_message) {
        return tplMessageText(this, obj, text_message);
    }

    renderMEPMessage () {
        return tplMepMessage(this);
    }

    renderInfoMessage () {
        return tplInfoMessage(this);
    }

    renderFileProgress () {
        if (!this.model.file) {
            // Can happen when file upload failed and page was reloaded
            return '';
        }
        return tplFileProgress(this);
    }

    // custom change - new functions
    // custom change START
    renderQuickReplies (arr) {
        return tplQuickReplies(this, arr);
    }

    renderBillInfo (obj) {
        return tplBillInfoCard(this, obj);
    }

    renderPaCard (obj) {
        return tplPaCard(this, obj);
    }

    renderPcCard (obj) {
        return tplPcCard(this, obj);
    }

    // eslint-disable-next-line class-methods-use-this
    renderThankYouCard (obj) {
        return tplThankYouCard(obj);
    }

    renderOffersCarousel (obj) {
        return tplOffersCarousel(this, obj);
    }

    renderOffersCard (offer, obj, index) {
        return tplOffersCard(this, offer, obj, index);
    }

    renderOOBCharges (obj) {
        return tplOOBChargesCard(this, obj);
    }

    renderOOBChargesDetailed (obj) {
        return tplOOBChargesDetailedCard(this, obj);
    }

    renderOOBExtendedDetailed (item, obj) {
        return tplOOBExtendedDetailedCard(this, item, obj);
    }

    renderOOBExtendedCarousel (obj) {
        return tplOOBExtendedCarousel(this, obj);
    }

    renderOOBChargesBillMgmt (obj) {
        return tplOOBChargesBillMgmtCard(this, obj);
    }

    renderInfoTooltip (obj, id) {
        return tplInfoTooltipModal(this, obj, id);
    }

    renderMyPlanCard (obj) {
        return tplMyPlanCard(this, obj);
    }
    // custom change END

    renderChatMessage () {
        // custom change START
        let isCustomMsg = false;
        let customMsgType = '';
        let quickRepliesButtons = [];
        const calloutButton = [];
        let billInfoObject, billInfoCard, pcCard, thankYouCard, paCard, offersArray, offersCarousel, OOBChargesObject, OOBChargesCard, OOBChargesDetailedObject, OOBChargesDetailedCard, OOBExtendedDetailedCard, OOBChargesBillMgmtCard, OOBExtendedDetailedObject, myPlanObject, myPlanCard;
        const customElements = ["billInfoCard", "PCCard", "ThankYouCard", "PACard", "OffersCarousel", "OOBChargesCard", "OOBChargesDetailedCard","OOBExtendedCard","OOBChargesBillMgmtCard","MyPlanCard"];
        const lastChatbotMessages = this.model.collection.last().chatbox.messages.models.filter(model => model?.getMessageText().includes('##XMPP_CUSTOM_MSG##') && model?.get("sender") === "them" );
        const lastMeMessages = this.model.collection.last().chatbox.messages.models.filter(model => model?.get("sender") === "me" );
        const lastChatbotMessage =lastChatbotMessages?.[lastChatbotMessages.length - 1];
        const lastMeMessage = lastMeMessages?.[lastMeMessages.length - 1];
        const lastChatbotMessageIndex = this.model.collection.last().chatbox.messages.models.indexOf(lastChatbotMessage)
        const lastMeMessageIndex = this.model.collection.last().chatbox.messages.models.indexOf(lastMeMessage)
        let msgText = this.model.getMessageText()
        if(msgText?.includes('##XMPP_CUSTOM_MSG##')) {
            isCustomMsg = true
            const jsonString = msgText.match(new RegExp('##XMPP_CUSTOM_MSG##' + "(.*)"))
            if(jsonString?.[1]) {
                const jsonObject = JSON.parse(jsonString[1])
                const textPlainArray = []
                if(jsonObject?.messages?.message?.length > 0) {
                    jsonObject.messages.message.forEach((messageItem) => {
                        if(messageItem?.content?.buttons?.length > 0) {
                            messageItem?.content?.buttons?.forEach((button) => {
                                if((this.model?.cid === lastChatbotMessage?.cid && lastChatbotMessageIndex > lastMeMessageIndex) && button.action?.type?.toLowerCase() === "imback") {
                                    customMsgType = 'quickReplies';
                                    quickRepliesButtons.push(button.title);
                                }
        
                                // Get the json link (calloutButton)
                                if((button?.action?.type === "openUrl")) {
                                    // in the textPlainArray we add the string 'calloutButton' in order to know that there should be a callout button
                                    if(customElements.includes(textPlainArray[textPlainArray.length - 1]) || textPlainArray.length === 0) {
                                        textPlainArray.push(`{"calloutbutton" : {"name": "${button.title}", "url": "${button.action.value}"}}`);
                                    } else {
                                        textPlainArray[textPlainArray.length - 1] = textPlainArray[textPlainArray.length - 1] + `{"calloutbutton" : {"name": "${button.title}", "url": "${button.action.value}"}}\n`;
                                    }
                                }
                            })
                        }
                        // Get the json message text
                        if(messageItem?.content?.textMessage?.textPlain) {
                            // Push each message into an array
                            if(messageItem.content.textMessage.textPlain.includes("\"template\":\"billInfoCard\"")) {
                                billInfoObject = JSON.parse(messageItem.content.textMessage.textPlain).output.output;
                                billInfoCard = this.createBillInfoCard(billInfoObject);
                                textPlainArray.push("billInfoCard");
                            } else if(messageItem.content.textMessage.textPlain.includes("\"template\":\"PCCard\"")) {
                                pcCard = JSON.parse(messageItem.content.textMessage.textPlain).output.output;
                                textPlainArray.push("PCCard");
                            } else if(messageItem.content.textMessage.textPlain.includes("\"template\":\"ThankYouCard\"")) {
                                thankYouCard = JSON.parse(messageItem.content.textMessage.textPlain).output.output;
                                textPlainArray.push("ThankYouCard");
                            } else if(messageItem.content.textMessage.textPlain.includes("\"template\":\"PACard\"")) {
                                paCard = JSON.parse(messageItem.content.textMessage.textPlain).output.output;
                                textPlainArray.push("PACard");
                            } else if(messageItem.content.textMessage.textPlain.includes("\"offersTemplate\":\"OffersCarousel\"")) {
                                offersArray = JSON.parse(messageItem.content.textMessage.textPlain);
                                offersCarousel =this.createOffersCarousel(offersArray);
                                textPlainArray.push("OffersCarousel");
                            } else if(messageItem.content.textMessage.textPlain.includes("\"template\":\"OOBChargesCard\"")) {
                                OOBChargesObject = JSON.parse(messageItem.content.textMessage.textPlain).output.output;
                                OOBChargesCard = this.createOOBChargesCard(OOBChargesObject);
                                textPlainArray.push("OOBChargesCard");
                            } else if(messageItem.content.textMessage.textPlain.includes("\"template\":\"OOBChargesDetailedCard\"")) {
                                OOBChargesDetailedObject = JSON.parse(messageItem.content.textMessage.textPlain).output.output;
                                OOBChargesDetailedCard = this.createOOBChargesDetailedCard(OOBChargesDetailedObject);
                                textPlainArray.push("OOBChargesDetailedCard");
                            } else if(messageItem.content.textMessage.textPlain.includes("\"template\":\"billMgmtDetailedCard\"")) {
                                OOBChargesDetailedObject = JSON.parse(messageItem.content.textMessage.textPlain).output.output;
                                OOBChargesBillMgmtCard = this.createOOBChargesBillMgmtCard(OOBChargesDetailedObject?.customerBill);
                                textPlainArray.push("OOBChargesBillMgmtCard");
                            } else if(messageItem.content.textMessage.textPlain.includes("\"template\":\"OOBExtendedCard\"")) {
                                OOBExtendedDetailedObject = JSON.parse(messageItem.content.textMessage.textPlain);
                                OOBExtendedDetailedCard = this.createOOBExtendedDetailedCard(OOBExtendedDetailedObject,messageItem.messageId);
                                textPlainArray.push("OOBExtendedCard");
                            } else if(messageItem.content.textMessage.textPlain.includes("\"template\":\"MyPlanCard\"")) {
                                myPlanObject = JSON.parse(messageItem.content.textMessage.textPlain);
                                myPlanCard = this.createMyPlanCard(myPlanObject);
                                textPlainArray.push("MyPlanCard");
                            } else if (customElements.includes(textPlainArray[textPlainArray.length - 1]) || textPlainArray.length === 0) {
                                // Verify if the last element in the array is a custom element or a simple text; if the last element is a text, then add the new  text to that one, but if it is a custom element, then add a new text elemment to the array;
                                textPlainArray.push(messageItem.content.textMessage.textPlain + "\n");                           
                            } else {
                                textPlainArray[textPlainArray.length - 1] = textPlainArray[textPlainArray.length - 1] + messageItem.content.textMessage.textPlain + "\n";
                            }                             
                        }
                    })
                    
                    msgText = textPlainArray;
                } else {
                    msgText = [__('Error: no message')];
                }
            }
        } else if (!msgText?.includes('##NO_XMPP_MSG##')) {
            msgText = [msgText];
        }
        if(msgText?.includes('##NO_XMPP_MSG##')) {
            return;
        }
        // custom change END

        return tplMessage(this, this.getProps(), {
            'is_custom_msg': isCustomMsg,
            'custom_msg_type': customMsgType,
            'quick_replies_buttons': quickRepliesButtons,
            'callout_button': calloutButton,
            'text_message': msgText,
            'bill_info': billInfoCard,
            'pc_info': pcCard,
            'pa_info': paCard,
            'thank_you_card': thankYouCard,
            'offer_info': offersCarousel,
            'oob_charges_info': OOBChargesCard,
            'oob_charges_detailed_info': OOBChargesDetailedCard,
            'oob_extended_detailed_info': OOBExtendedDetailedCard,
            'oob_charges_bill_mgmt_info': OOBChargesBillMgmtCard,
            'my_plan_info': myPlanCard,
        }); // custom change - was "return tplMessage(this, this.getProps());"
    }

    // custom change - new functions for bill info
    // custom change START

    //function to create a bill info card
    createBillInfoCard (billInfoObject) {
        let channel = "web";
        if(window.ReactNativeWebView && billInfoObject?.payLink?.startsWith("navigation:")){
            channel = "mva";
        }

        let paidBill;
        if(billInfoObject.financialAccount.accountBalance.amount.value) {
            if(Number(billInfoObject.financialAccount.accountBalance.amount.value) <= 0) {
                paidBill = true;
            } else {
                paidBill = false;
            }
        }

        const billDateTime = billInfoObject.billDate;
        const validMonth = this.convertMonth(billDateTime, true);
        const billDateInterval = this.convertBillDateInterval(billDateTime)

        const dueDateTime = billInfoObject.paymentDueDate;
        const dueMonth = this.convertMonth(dueDateTime, false);

        return {
            'bill_date_interval' : billDateInterval,
            'paid' : paidBill,
            'valid_month' : validMonth,
            'due_day' : dayjs(dueDateTime).format('D'),
            'due_month' : dueMonth,
            'amount_value' : Number(billInfoObject.financialAccount.accountBalance.amount.value),
            'amount_unit' : billInfoObject.financialAccount.accountBalance.amount.unit,
            'channel' : channel,
            'details_link' : billInfoObject.detailsLink ? billInfoObject.detailsLink : false,
            'pay_link' : billInfoObject.payLink,
            'phone_number' : billInfoObject.billInfoPhoneNumber ? billInfoObject.billInfoPhoneNumber : billInfoObject.billInfoBan
        };
    }

    //function to convert a month number into text
    // eslint-disable-next-line class-methods-use-this
    convertMonth = function (date, subtract) {
        let month;
        if(subtract) {
            month = dayjs(date).subtract(1, 'day').format('M');
        } else {
            month = dayjs(date).format('M');
        }
        const months = ["Ianuarie","Februarie","Martie","Aprilie","Mai","Iunie","Iulie","August","Septembrie","Octombrie","Noiembrie","Decembrie"];
        return months[Number(month) - 1];
    }

    // eslint-disable-next-line class-methods-use-this
    convertBillDateInterval= function (date) {
        const months = ["ian.","feb.","mart.","apr.","mai","iun.","iul.","aug.","sept.","oct.","nov.","dec."];
        const year = dayjs(date).subtract(1, 'day').format('YYYY');
        const firstDate = `${dayjs(date).subtract(1, 'month').format("D")} ${months[dayjs(date).subtract(1, 'month').format("M") - 1]}`;
        const secondDate = `${dayjs(date).subtract(1, 'day').format('D')} ${months[dayjs(date).subtract(1, 'day').format('M') - 1]}`;
        
        return `${year} | ${firstDate} - ${secondDate}`;
    }

    // eslint-disable-next-line class-methods-use-this
    convertOOBDateInterval= function (date) {
        const months = ["Ian.","Feb.","Mart.","Apr.","Mai","Iun.","Iul.","Aug.","Sept.","Oct.","Nov.","Dec."];
        const firstDate = `${dayjs(date).subtract(1, 'month').format("D")} ${months[dayjs(date).subtract(1, 'month').format("M") - 1]}`;
        const secondDate = `${dayjs(date).subtract(1, 'day').format('D')} ${months[dayjs(date).subtract(1, 'day').format('M') - 1]}`;
        
        return `${firstDate} - ${secondDate}`;
    }

    //function to create a OOB Charges Detailed card
    // eslint-disable-next-line class-methods-use-this
    createOOBExtendedDetailedCard (OOBExtendedDetailedObj, id) {
        return {
            'charges': OOBExtendedDetailedObj.OOBExtCard.sort((a,b) => (Number(b?.Total) || 0) - (Number(a?.Total) || 0)),
            'info_tooltip': OOBExtendedDetailedObj.infoTooltip,
            'id' : id,
        };
    }

    //function to create a OOB Charges Detailed card
    // eslint-disable-next-line class-methods-use-this
    createOOBChargesBillMgmtCard (OOBChargesDetailedObj) {
        const charges = OOBChargesDetailedObj?.[0]?.taxItem?.reduce((acc, taxItem) => {
            if (taxItem.taxCategory) {
                acc[taxItem.taxCategory] = taxItem?.taxAmount?.value || 0;
            }
            return acc;
        }, {})
        return {
            'bill_date': dayjs(OOBChargesDetailedObj?.[0]?.billDate).format('DD.MM.YYYY'),
            'bill_no': OOBChargesDetailedObj?.[0]?.billNo,
            'charges' : charges,
            'unit' : OOBChargesDetailedObj?.[0]?.amountDue?.unit,
            'total_without_tva' : OOBChargesDetailedObj?.[0]?.taxExcludedAmount?.value || 0,
        };
    }

    //function to create a OOB Charges Detailed card
    // eslint-disable-next-line class-methods-use-this
    createOOBChargesDetailedCard (OOBChargesDetailedObj) {
        const {Abonamente_si_extraoptiuni , ...filteredObj} = OOBChargesDetailedObj.response[0].billSummaryItemCategoryTotalList;
        return {
            'bill_date': dayjs(OOBChargesDetailedObj?.response?.[0]?.bill?.billDate).format('DD.MM.YYYY'),
            'bill_no': OOBChargesDetailedObj?.response?.[0]?.bill?.billNo,
            'charges' : OOBChargesDetailedObj?.response?.[0]?.billSummaryItemCategoryTotalList?.Abonamente_si_extraoptiuni ? {Abonamente_si_extraoptiuni , ...filteredObj} : OOBChargesDetailedObj.response[0].billSummaryItemCategoryTotalList,
            'other_charges' : OOBChargesDetailedObj?.OOBCalculatedCosts ? Number(OOBChargesDetailedObj?.OOBCalculatedCosts || 0) : null,
            'TVA' : OOBChargesDetailedObj?.response?.[0]?.bill?.taxItem?.filter(item => item?.taxCategory?.includes("TVA"))?.[0]?.taxAmount?.value,
            'unit' : OOBChargesDetailedObj?.response?.[0]?.bill?.amountDue?.unit,
            'bill_balance' : Number(OOBChargesDetailedObj?.billBalance || 0),
            'total' : Number(OOBChargesDetailedObj?.response?.[0]?.bill?.amountDue?.value || 0),
        };
    }

    //function to create a OOB Extended Detailed card
    // eslint-disable-next-line class-methods-use-this

    //function to create a OOB Charges card
    createOOBChargesCard (OOBChargesObj) {
        let channel = "web";
        if(window.ReactNativeWebView && OOBChargesObj?.payLink?.startsWith("navigation:")){
            channel = "mva";
        }

        let paidBill;
        let receivableBalance = 0;
        if(OOBChargesObj.billBalance || OOBChargesObj.customerBill?.[0]) {
            if(Number(OOBChargesObj.billBalance) <= 0 || OOBChargesObj.customerBill?.[0]?.financialAccount?.accountBalance?.filter(item => item.balanceType === "receivable balance")?.[0]?.amount?.value <= 0) {
                paidBill = true;
            } else {
                receivableBalance = OOBChargesObj.billBalance ? Number(OOBChargesObj.billBalance) : OOBChargesObj.customerBill?.[0]?.financialAccount?.accountBalance?.filter(item => item.balanceType === "receivable balance")?.[0]?.amount?.value;
                paidBill = false;
            }
        }

        let object, extra_charges, amount_value;
        if(OOBChargesObj.response) {
            object = OOBChargesObj.response?.[0]?.bill;
            extra_charges = Number(object?.taxItem?.filter(item => item.taxCategory === "Extra Charge")?.[0]?.taxAmount?.value) || 0;
            amount_value = Number(object?.amountDue?.value) || 0;
        } else {
            object = OOBChargesObj.customerBill?.[0];
            extra_charges = object?.accountExtraCost || 0;
            amount_value = object?.taxItem?.filter(item => item.taxCategory === "Total de plata")?.[0]?.taxAmount?.value || 0;
        }

        const billDateTime = object.billDate;
        const validMonth = this.convertMonth(billDateTime, true);
        const billDateInterval = this.convertOOBDateInterval(billDateTime)

        return {
            'bill_date_interval' : billDateInterval,
            'paid' : paidBill,
            'valid_month' : validMonth,
            'amount_value' : amount_value,
            'extra_charges' : extra_charges,
            'amount_unit' : object?.amountDue?.unit,
            'channel' : channel,
            'pay_link' : OOBChargesObj.payLink,
            'download_bill_link' : OOBChargesObj.downloadBillLink,
            'receivable_balance' : receivableBalance,
        };
    }
    // custom change END

    //function to create my plan card
    // eslint-disable-next-line class-methods-use-this
    createMyPlanCard (myPlanObj) {
        let addons, benefits, base_plan;
        try {
            addons = myPlanObj?.AddPlan?.map(item => JSON.parse(item?.replaceAll("\\\"", "\"").replaceAll("'","\"")));
            benefits = JSON.parse(myPlanObj?.Benefits?.replaceAll("\\\"", "\"").replaceAll("'","\""));
            base_plan = JSON.parse(myPlanObj?.BasePlan?.replaceAll("\\\"", "\"").replaceAll("'","\""));
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
        return {
            "addons" : myPlanObj?.AddPlan ? addons : null,
            "benefits" : myPlanObj?.Benefits ? benefits : null,
            "base_plan" : myPlanObj?.BasePlan ? base_plan : null
        };
    }

    // custom change - new functions for offers carousel
    // custom change START
    //function to create an offers carousel
    // eslint-disable-next-line class-methods-use-this
    createOffersCarousel = function (arr) {
        const index = arr.findIndex(object => object.offersUrl && object.offersTemplate);
        const sortedArray = [...arr];
        sortedArray.splice(index, 1);
        sortedArray.sort((a, b) => {
            return Number(a.Cost) - Number(b.Cost);
        });
        let channel = "web";
        if(window.ReactNativeWebView && arr?.[index]?.offersUrl?.startsWith("navigation:")){
            channel = "mva";
        }
        const descriptions = sortedArray.map((item) => item.Descriere.split("+ ").map((benefit) => {
            if(benefit.includes("sunt disponibile si in roaming in Spatiul Economic European")){
            return benefit.split(". ");
            } else {
            return benefit;
            }
        }).flat());

        return {
            'offers' : sortedArray.map((item) => {
                return {
                    ...item, 
                    Valabilitate : (/^\d+ D$/).test(item.Valabilitate)? item.Valabilitate.replace("D","zile") : item.Valabilitate, 
                    Cost : Number.parseFloat(item.Cost).toFixed()}
            }),
            'descriptions' : descriptions,
            'channel' : channel,
            'url' : arr[index].offersUrl
        };
    }
    // custom change END

    shouldShowAvatar () {
        return api.settings.get('show_message_avatar') &&
            !this.model.isMeCommand() &&
            ['chat', 'groupchat', 'normal'].includes(this.model.get('type'));
    }

    onUnfurlAnimationEnd () {
        if (this.model.get('url_preview_transition') === 'fade-out') {
            this.model.save({
                'hide_url_previews': true,
                'url_preview_transition': 'fade-in'
            });
        }
    }

    async onRetryClicked () {
        this.show_spinner = true;
        this.requestUpdate();
        await api.trigger(this.model.get('retry_event_id'), {'synchronous': true});
        this.model.destroy();
        this.parentElement.removeChild(this);
    }

    // custom change - new function
    // custom change START
    async onQuickReplyClicked (ev) {
        ev.preventDefault();
        const message_text = ev.target.innerText;
        if (
            (api.settings.get('message_limit') && message_text.length > api.settings.get('message_limit')) ||
            !message_text.replace(/\s/g, '').length
        ) {
            return;
        }
        if (!_converse.connection.authenticated) {
            const err_msg = __('Sorry, the connection has been lost, and your message could not be sent');
            api.alert('error', __('Error'), err_msg);
            api.connection.reconnect();
            return;
        }
        let spoiler_hint,
            hint_el = {};
        if (this.model.get('composing_spoiler')) {
            hint_el = this.querySelector('form.sendXMPPMessage input.spoiler-hint');
            spoiler_hint = hint_el.value;
        }

        const is_command = await parseMessageForCommands(this.model, message_text);
        const message = is_command ? null : await this.model.chatbox.sendMessage({'body': message_text, spoiler_hint});
        if (is_command || message) {
            hint_el.value = '';
            this.model.set({'draft': ''});
        }
        if (api.settings.get('view_mode') === 'overlayed') {
            // XXX: Chrome flexbug workaround. The .chat-content area
            // doesn't resize when the textarea is resized to its original size.
            const chatview = _converse.chatboxviews.get(this.getAttribute('jid'));
            const msgs_container = chatview.querySelector('.chat-content__messages');
            msgs_container.parentElement.style.display = 'none';
        }

        if (api.settings.get('view_mode') === 'overlayed') {
            // XXX: Chrome flexbug workaround.
            const chatview = _converse.chatboxviews.get(this.getAttribute('jid'));
            const msgs_container = chatview.querySelector('.chat-content__messages');
            msgs_container.parentElement.style.display = '';
        }
        // Suppress events, otherwise superfluous CSN gets set
        // immediately after the message, causing rate-limiting issues.
        this.model.chatbox.setChatState(_converse.ACTIVE, { 'silent': true });
        this.removeElements(document.querySelectorAll(".short-answer-container"));
    }

    async onPCReplyClicked (ev) {
        ev.preventDefault();
        let message_text = ev.target.innerText;
        if(ev.target.parentElement.previousElementSibling.previousElementSibling.classList.contains("pc-info-partial-sum")){
            const input_sum = ev.target.parentElement.previousElementSibling.previousElementSibling;
            const error_message = ev.target.parentElement.previousElementSibling;
            const number_regex = /^\d+(\.\d+)?$/gm;
            if(number_regex.test(input_sum.value)) {
                input_sum.classList = "pc-info-input pc-info-partial-sum";
                error_message.classList = "pc-info-input-feedback pc-info-input-feedback--not-displayed";
                message_text = input_sum.value;
                
            } else {
                input_sum.classList = "pc-info-input pc-info-partial-sum pc-info-input-error";
                error_message.classList = "pc-info-input-feedback";
                return;
            }
        }
        if (
            (api.settings.get('message_limit') && message_text.length > api.settings.get('message_limit')) ||
            !message_text.replace(/\s/g, '').length
        ) {
            return;
        }
        if (!_converse.connection.authenticated) {
            const err_msg = __('Sorry, the connection has been lost, and your message could not be sent');
            api.alert('error', __('Error'), err_msg);
            api.connection.reconnect();
            return;
        }
        let spoiler_hint,
            hint_el = {};
        if (this.model.get('composing_spoiler')) {
            hint_el = this.querySelector('form.sendXMPPMessage input.spoiler-hint');
            spoiler_hint = hint_el.value;
        }

        const is_command = await parseMessageForCommands(this.model, message_text);
        const message = is_command ? null : await this.model.chatbox.sendMessage({'body': message_text, spoiler_hint});
        if (is_command || message) {
            hint_el.value = '';
            this.model.set({'draft': ''});
        }
        if (api.settings.get('view_mode') === 'overlayed') {
            // XXX: Chrome flexbug workaround. The .chat-content area
            // doesn't resize when the textarea is resized to its original size.
            const chatview = _converse.chatboxviews.get(this.getAttribute('jid'));
            const msgs_container = chatview.querySelector('.chat-content__messages');
            msgs_container.parentElement.style.display = 'none';
        }

        if (api.settings.get('view_mode') === 'overlayed') {
            // XXX: Chrome flexbug workaround.
            const chatview = _converse.chatboxviews.get(this.getAttribute('jid'));
            const msgs_container = chatview.querySelector('.chat-content__messages');
            msgs_container.parentElement.style.display = '';
        }
        // Suppress events, otherwise superfluous CSN gets set
        // immediately after the message, causing rate-limiting issues.
        this.model.chatbox.setChatState(_converse.ACTIVE, { 'silent': true });
    }

    // eslint-disable-next-line class-methods-use-this
    onFocus(ev) {
        ev.preventDefault();
        const mobileDevices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        if (mobileDevices.test(navigator.userAgent)) {
            const chatContent = document.querySelector('.chat-content');
            if(chatContent) {
                if(chatContent.scrollHeight - chatContent.scrollTop === chatContent.clientHeight) {
                chatContent.scrollTo(0, chatContent.scrollHeight);
                } else {
                chatContent.scrollTo(0, chatContent.scrollTop);
                }
            }
        }
    }

    async onPAReplyClicked (ev) {
        ev.preventDefault();
        let message_text;
        if(ev.target.parentElement.previousElementSibling.previousElementSibling.classList.contains("pa-info-date")){
            const input_date = ev.target.parentElement.previousElementSibling.previousElementSibling;
            const error_message = ev.target.parentElement.previousElementSibling;
            const number_regex = /(20)\d{2}-(0\d{1}|1[0-2])-([0-2]\d{1}|3[0-1])/;
            if(number_regex.test(dayjs(input_date.value).format('YYYY-MM-DD')) && dayjs(input_date.value).isAfter(dayjs(input_date.getAttribute('min')).subtract(1, 'day')) && dayjs(input_date.value).isBefore(dayjs(input_date.getAttribute('max')).add(1, 'day'))) {
                input_date.classList = "pa-info-input pa-info-date";
                error_message.classList = "pa-info-input-feedback pa-info-input-feedback--not-displayed";
                message_text = dayjs(input_date.value).format('YYYY-MM-DD');
                
            } else {
                if(!number_regex.test(dayjs(input_date.value).format('YYYY-MM-DD'))) {
                    error_message.textContent = "Formatul datei nu este valid";
                } else {
                    error_message.textContent = "Data aleasa nu se afla in intervalul specificat";
                }   
                input_date.classList = "pa-info-input pa-info-date pa-info-input-error";
                error_message.classList = "pa-info-input-feedback";
                return;
            }
        }
        if (
            (api.settings.get('message_limit') && message_text.length > api.settings.get('message_limit')) ||
            !message_text.replace(/\s/g, '').length
        ) {
            return;
        }
        if (!_converse.connection.authenticated) {
            const err_msg = __('Sorry, the connection has been lost, and your message could not be sent');
            api.alert('error', __('Error'), err_msg);
            api.connection.reconnect();
            return;
        }
        let spoiler_hint,
            hint_el = {};
        if (this.model.get('composing_spoiler')) {
            hint_el = this.querySelector('form.sendXMPPMessage input.spoiler-hint');
            spoiler_hint = hint_el.value;
        }

        const is_command = await parseMessageForCommands(this.model, message_text);
        const message = is_command ? null : await this.model.chatbox.sendMessage({'body': message_text, spoiler_hint});
        if (is_command || message) {
            hint_el.value = '';
            this.model.set({'draft': ''});
        }
        if (api.settings.get('view_mode') === 'overlayed') {
            // XXX: Chrome flexbug workaround. The .chat-content area
            // doesn't resize when the textarea is resized to its original size.
            const chatview = _converse.chatboxviews.get(this.getAttribute('jid'));
            const msgs_container = chatview.querySelector('.chat-content__messages');
            msgs_container.parentElement.style.display = 'none';
        }

        if (api.settings.get('view_mode') === 'overlayed') {
            // XXX: Chrome flexbug workaround.
            const chatview = _converse.chatboxviews.get(this.getAttribute('jid'));
            const msgs_container = chatview.querySelector('.chat-content__messages');
            msgs_container.parentElement.style.display = '';
        }
        // Suppress events, otherwise superfluous CSN gets set
        // immediately after the message, causing rate-limiting issues.
        this.model.chatbox.setChatState(_converse.ACTIVE, { 'silent': true });
    }

    async onActivateOfferClicked (ev) {
        ev.preventDefault();
        const message_text = ev.target.getAttribute("activate");
        if (
            (api.settings.get('message_limit') && message_text.length > api.settings.get('message_limit')) ||
            !message_text.replace(/\s/g, '').length
        ) {
            return;
        }
        if (!_converse.connection.authenticated) {
            const err_msg = __('Sorry, the connection has been lost, and your message could not be sent');
            api.alert('error', __('Error'), err_msg);
            api.connection.reconnect();
            return;
        }
        let spoiler_hint,
            hint_el = {};
        if (this.model.get('composing_spoiler')) {
            hint_el = this.querySelector('form.sendXMPPMessage input.spoiler-hint');
            spoiler_hint = hint_el.value;
        }

        const is_command = await parseMessageForCommands(this.model, message_text);
        const message = is_command ? null : await this.model.chatbox.sendMessage({'body': message_text, spoiler_hint});
        if (is_command || message) {
            hint_el.value = '';
            this.model.set({'draft': ''});
        }
        if (api.settings.get('view_mode') === 'overlayed') {
            // XXX: Chrome flexbug workaround. The .chat-content area
            // doesn't resize when the textarea is resized to its original size.
            const chatview = _converse.chatboxviews.get(this.getAttribute('jid'));
            const msgs_container = chatview.querySelector('.chat-content__messages');
            msgs_container.parentElement.style.display = 'none';
        }

        if (api.settings.get('view_mode') === 'overlayed') {
            // XXX: Chrome flexbug workaround.
            const chatview = _converse.chatboxviews.get(this.getAttribute('jid'));
            const msgs_container = chatview.querySelector('.chat-content__messages');
            msgs_container.parentElement.style.display = '';
        }
        // Suppress events, otherwise superfluous CSN gets set
        // immediately after the message, causing rate-limiting issues.
        this.model.chatbox.setChatState(_converse.ACTIVE, { 'silent': true });
    }

    // eslint-disable-next-line class-methods-use-this
    onMvaNavigationClicked (ev) {
        ev.preventDefault();
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(ev.target.getAttribute("link"));
        }
    }

    // eslint-disable-next-line class-methods-use-this
    onRightArrowClicked (ev) {
        ev.preventDefault();
        ev.target.parentElement.scrollBy({
            top: 0,
            left: ev.target.previousElementSibling.offsetWidth + 15,
            behavior: "smooth",
        });
    }

    // eslint-disable-next-line class-methods-use-this
    onLeftArrowClicked (ev) {
        ev.preventDefault();
        ev.target.parentElement.scrollBy({
            top: 0,
            left: -(ev.target.nextElementSibling.offsetWidth + 15),
            behavior: "smooth",
        });
    }

    // eslint-disable-next-line class-methods-use-this
    onTooltipClicked (ev, id) {
        ev.preventDefault();
        document.getElementById(id).style.display="flex";
        window.removeEventListener('click', (event) =>  this.hideElement(event, id));
        window.addEventListener('click', (event) =>  this.hideElement(event, id));
    }

    // eslint-disable-next-line class-methods-use-this
    hideElement (event, id) {
        if (event.target == document.getElementById(id)) {
            document.getElementById(id).style.display = "none";
        }
    }

    // eslint-disable-next-line class-methods-use-this
    onCloseTooltip (ev, id) {
        ev.preventDefault();
        document.getElementById(id).style.display="none";
    }
    // custom change  END
    
    isRetracted () {
        return this.model.get('retracted') || this.model.get('moderated') === 'retracted';
    }

    hasMentions () {
        const is_groupchat = this.model.get('type') === 'groupchat';
        return is_groupchat && this.model.get('sender') === 'them' && this.chatbox.isUserMentioned(this.model);
    }

    getOccupantAffiliation () {
        return this.model.occupant?.get('affiliation');
    }

    getOccupantRole () {
        return this.model.occupant?.get('role');
    }

    getExtraMessageClasses () {
        const extra_classes = [
            this.model.isFollowup() ? 'chat-msg--followup' : null,
            this.model.get('is_delayed') ? 'delayed' : null,
            this.model.isMeCommand() ? 'chat-msg--action' : null,
            this.isRetracted() ? 'chat-msg--retracted' : null,
            this.model.get('type'),
            this.shouldShowAvatar() ? 'chat-msg--with-avatar' : null,
        ].map(c => c);

        if (this.model.get('type') === 'groupchat') {
            extra_classes.push(this.getOccupantRole() ?? '');
            extra_classes.push(this.getOccupantAffiliation() ?? '');
            if (this.model.get('sender') === 'them' && this.hasMentions()) {
                extra_classes.push('mentioned');
            }
        }
        this.model.get('correcting') && extra_classes.push('correcting');
        return extra_classes.filter(c => c).join(" ");
    }

    getDerivedMessageProps () {
        const format = api.settings.get('time_format');
        return {
            'pretty_time': dayjs(this.model.get('edited') || this.model.get('time')).format(format),
            'has_mentions': this.hasMentions(),
            'hats': getHats(this.model),
            'is_first_unread': this.chatbox.get('first_unread_id') === this.model.get('id'),
            'is_me_message': this.model.isMeCommand(),
            'is_retracted': this.isRetracted(),
            'username': this.model.getDisplayName(),
            'should_show_avatar': this.shouldShowAvatar(),
        }
    }

    getRetractionText () {
        if (['groupchat', 'mep'].includes(this.model.get('type')) && this.model.get('moderated_by')) {
            const retracted_by_mod = this.model.get('moderated_by');
            const chatbox = this.model.collection.chatbox;
            if (!this.model.mod) {
                this.model.mod =
                    chatbox.occupants.findOccupant({'jid': retracted_by_mod}) ||
                    chatbox.occupants.findOccupant({'nick': Strophe.getResourceFromJid(retracted_by_mod)});
            }
            const modname = this.model.mod ? this.model.mod.getDisplayName() : 'A moderator';
            return __('%1$s has removed this message', modname);
        } else {
            return __('%1$s has removed this message', this.model.getDisplayName());
        }
    }

    showUserModal (ev) {
        if (this.model.get('sender') === 'me') {
            api.modal.show('converse-profile-modal', {model: this.model}, ev);
        } else if (this.model.get('type') === 'groupchat') {
            ev.preventDefault();
            api.modal.show('converse-muc-occupant-modal', { 'model': this.model.getOccupant(), 'message': this.model }, ev);
        } else {
            ev.preventDefault();
            const chatbox = this.model.collection.chatbox;
            api.modal.show('converse-user-details-modal', { model: chatbox }, ev);
        }
    }

    showMessageVersionsModal (ev) {
        ev.preventDefault();
        api.modal.show('converse-message-versions-modal', {'model': this.model}, ev);
    }

    toggleSpoilerMessage (ev) {
        ev?.preventDefault();
        this.model.save({'is_spoiler_visible': !this.model.get('is_spoiler_visible')});
    }
}

api.elements.define('converse-chat-message', Message);
